<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-text-field :value="orderNum" @input="setOrderNum" placeholder="Order number" flat hide-details solo-inverted 
                v-mask="'XXX-#########'" @keydown.enter="loadOrder" ref="orderNum" @focus="focusField = 'orderNum'" @blur="onBlur"></v-text-field>
        </div>

        <v-card flat class="mt-3" :loading="loadingView">
            <v-card-text v-show="!compact" :class="order != null && order.changes.length > 0 ? 'modified rounded' : 'rounded'">
                <v-row v-if="order != null">
                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Order Information</div>

                        <div class="text-left">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip :color="orderStatusColor(order.status)" label class="white--text mb-3 headline" 
                                        v-bind="attrs" v-on="on">
                                        {{ order.foreignId }}
                                    </v-chip>
                                </template>
                                <span>{{ order.status }}</span>
                            </v-tooltip>
                        </div>

                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <th>Status</th>
                                    <td>{{ order.status }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup location</th>
                                    <td>[{{ order.pickupLocation.wintuxID}}] {{ order.pickupLocation.name }}  |  {{ order.pickupLocation.phone }}</td>
                                </tr>
                                <tr>
                                    <th>Book date</th>
                                    <td>{{ formatDate( order.orderDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Pickup date</th>
                                    <td>{{ formatDate( order.wantDate, 'YYYY-MM-DD @ hh:mm a' ) }}</td>
                                </tr>
                                <tr>
                                    <th>Use date</th>
                                    <td>{{ order.useDate }}</td>
                                </tr>
                                <tr>
                                    <th>Return date</th>
                                    <td>{{ order.returnDate }}</td>
                                </tr>
                                <tr>
                                    <th>Customer type</th>
                                    <td>{{ order.eventRole }}</td>
                                </tr>
                                <tr>
                                    <th>Event</th>
                                    <td>{{ order.eventId == '0' ? '' : order.eventId }}</td>
                                </tr>
                                <tr>
                                    <th>Clerk</th>
                                    <td>{{ order.employee }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <div class="subtitle-1 my-3">Comments</div>
                        <div>{{ order.comments == '' ? 'No comments available' : order.comments }}</div>
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Customer Information</div>
                        <customer-info :customer="order.customer" :edit="false" :avatar="false" show-measurements />
                    </v-col>

                    <v-col cols="4">
                        <div class="subtitle-1 mb-3">Items</div>

                        <!-- Esto se comentó pq no se vana  escanear los items en tailoring -->
                        <!-- <barcode-field v-model="barcode" class="mb-3" ref="barcode" @focus="focusField = 'barcode'" @blur="onBlur"
                            @submit="openAlterationDialog" @openOrder="(b) => { setOrderNum(b); loadOrder(); }" /> -->

                        <package-viewer :value="orderPackage" :items-assigned="order.assignedItems" :changes="order.changes" itemsonly tailoring @altitem="setAlterationItem" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text v-show="compact" :class="order != null && order.changes.length > 0 ? 'modified rounded' : 'rounded'">
                <div v-if="order != null">
                    <div class="d-flex align-center">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip :color="orderStatusColor(order.status)" label class="white--text mb-3 headline" 
                                    v-bind="attrs" v-on="on">
                                    {{ order.foreignId }}
                                </v-chip>
                            </template>
                            <span>{{ order.status }}</span>
                        </v-tooltip>

                        <v-spacer></v-spacer>

                        <div class="mb-2" v-if="order.changes.length > 0">
                            <v-chip small label color="deep-orange" class="white--text">MODIFIED</v-chip>
                        </div>
                    </div>

                    <!-- Esto se comentó pq no se vana  escanear los items en tailoring -->
                    <!-- <barcode-field v-model="barcode" class="mb-3" ref="barcode2" @focus="focusField = 'barcode2'" @blur="onBlur"
                        @submit="openAlterationDialog" @openOrder="(b) => { setOrderNum(b); loadOrder(); }" /> -->

                    <package-viewer :value="orderPackage" :items-assigned="order.assignedItems" :changes="order.changes" itemsonly tailoring @altitem="setAlterationItem" />

                    <div class="subtitle-1 my-3">Comments</div>
                    <div>{{ order.comments == '' ? 'No comments available' : order.comments }}</div>
                </div>
            </v-card-text>

            <v-card-actions v-if="order != null">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn depressed @click="compact = !compact" icon v-on="on">
                            <v-icon>{{ compact ? 'mdi-view-compact-outline' : 'mdi-view-headline' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ compact ? 'Show full view' : 'Show compact view' }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="altDialog" width="500" persistent>
            <v-card>
                <v-card-title>Register alteration</v-card-title>
                <v-card-text v-if="altItem != null">
                    <v-list avatar three-line class="pt-0 mt-0">
                        <v-list-item>
                            <v-list-item-avatar v-if="altItem.imgSrc != undefined && altItem.imgSrc != null">
                                <v-img :src="altItem.imgSrc" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <div>{{ altItem.productName }}</div>
                                <div class="caption grey--text">{{ altItem.category }}</div>
                                
                                
                            </v-list-item-content>
                            <v-list-item-action>
                                <div class="grey--text">{{ altItem.foreignId }}</div>
                                <div class="grey--text">{{ altItem.size }}</div>
                                <div class="grey--text">{{ item.barcode }}</div>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field :value="item.altered" flat hide-details solo-inverted readonly></v-text-field>
                            <div class="caption ml-2">Current</div>
                        </v-col>
                        <v-col cols="6">
                            <v-form ref="form">
                                <v-text-field v-model="alt" flat solo-inverted persistent-hint @keydown="numbersOnly" hint="New"
                                    :rules="[rules.required, rules.isNumeric]" @keypress.enter.prevent="setAlteration"
                                    ref="alt"></v-text-field>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="altDialog = false">Close</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" :loading="altering" @click="setAlteration">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <force-dialog v-model="forceStatusDialog" :text="forceDialogText" btn-text="Yes, continue" :forcing="forcing" @force="forceLoadOrder" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CustomerInfo from '@/components/CustomerInfo.vue';
import PackageViewer from '@/components/PackageViewer.vue';
// import BarcodeField from '@/components/BarcodeField.vue';
import ForceDialog from '@/components/ForceDialog.vue';
import { mask } from 'vue-the-mask';
import moment from 'moment';
import { _st } from '@/softech';

export default {
    data: () => ({
        loadingView     : false,
        barcode         : '',
        focusField      : '',
        altDialog       : false,
        altItem         : null,
        alt             : '',

        altering        : false,

        compact         : true,  // show compact version when true

        forceStatusDialog   : false,

        forceDialogText     : '',
        forceReason         : '',
        forceReasons        : [],
        forcing             : false,

        rules           : {
            required        : v => !!v || 'required',
            isNumeric       : v => _st.isNumeric( v ) || 'must be numeric',
        },
    }),
    computed: {
        ...mapGetters({
            orderNum    : 'stations/tailoring/orderNum',
            order       : 'stations/tailoring/order'
        }),
        orderPackage() {
            if( this.order == null )
                return {};

            let m = this.order.measurements;
            let items = [];
            this.order.items.forEach(i => {
                let size = '';
                if( i.categoryId == 'C' )       size = `${m.coatSize}${m.coatLength} - ${m.coatSleeve}`;
                else if( i.categoryId == 'P' )  size = `${m.pantWaist}${m.pantType} - ${m.pantOutseam}`;
                else if( i.categoryId == 'S' )  size = `${m.shirtSize}`;
                else if( i.categoryId == 'N' )  size = `${m.tieSize}`;
                else if( i.categoryId == 'V' )  size = `${m.vestSize}`;
                else if( i.categoryId == 'SH' ) size = `${m.shoeSize}${m.shoeType}`;
                else if( i.categoryId == 'CB' ) size = `${m.cummerbundSize}`;
                else                            size = '';

                items.push({
                    ...i,
                    size
                });
            });

            return {
                items: items,
            };
        },
        item() {
            return this.order.assignedItems.find(i => i.categoryId == this.altItem.categoryId) ?? {};
            // return this.order.assignedItems.find(i => i.productId == this.altItem.productId) ?? {};
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.orderNum.focus();
        });
    },
    methods: {
        ...mapActions({
            setOrderNum     : 'stations/tailoring/setOrderNum',
            getOrder        : 'stations/tailoring/loadOrder',
            clearOrder      : 'stations/tailoring/clearOrder',
            registerAlt     : 'stations/tailoring/registerAlteration',
        }),
        async loadOrder() {
            try {
                if( this.orderNum == '' )
                    return;

                this.playBeep2();

                this.loadingView = true;
                this.clearOrder();
                let res = await this.getOrder();
                this.loadingView = false;

                if( res && res.canBeForced === true ) {
                    this.playWarning();
                    this.forceDialogText = res.text;
                    this.forceStatusDialog = true;
                    return;
                }

                this.setOrderNum('');
            }
            catch(error) {
                this.loadingView = false;
            }
        },
        async forceLoadOrder() {
            this.getOrder( true );
            this.forceStatusDialog = false;
        },
        openAlterationDialog() {
            try {
                if( this.barcode == '' )
                    return;

                let assignedItem = this.order.assignedItems.find(i => i.barcode == this.barcode);
                if( _st.isNUE( assignedItem ) ) {
                    this.barcode = '';
                    this.mxShowMessage('Barcode not found in order.', 'warning');
                    return; 
                }

                let item = this.orderPackage.items.find(i => i.categoryId == assignedItem.categoryId);
                if( _st.isNUE( item ) ) {
                    this.barcode = '';
                    this.mxShowMessage('There is no item in the order with the same category as the barcode scanned.', 'warning');
                    return; 
                }

                this.altItem = item;
                this.altDialog = true;
                // this.alt = item.size.substring( item.size.lastIndexOf(' ') + 1); // esto pone el valor indicado en el new alteration value box

                this.barcode = '';
                setTimeout(() => {
                    this.$refs.alt.focus();
                }, 500);
            }
            catch(error) {
                this.barcode = '';
                this.$refs.barcode.focus();
            }
        },
        setAlterationItem( i ) {
            this.altItem = i;
            this.altDialog = true;
            this.alt = i.size.substring( i.size.lastIndexOf(' ') + 1);


            setTimeout(() => {
                this.$refs.alt.focus();
            }, 500);
        },
        async setAlteration() {
            try {
                if( !this.$refs.form.validate() )
                    return;

                this.altering = true;
                await this.registerAlt({
                    itemId      : this.item.id,
                    alteration  : this.alt
                });
                this.altering = false;
                this.altDialog = false;
                this.$refs.form.reset();
            }
            catch(error) {
                this.altering = false;
            }
        },
        formatDate( date, f = 'YYYY-MM-DD' ) {
            return moment(date).format(f);
        },
        onBlur() {
            if( this.altDialog )
                return;

            setTimeout(() => {
                // if( this.order == null || this.focusField == 'orderNum' ) 
                    this.$refs.orderNum.focus();
                // else if( this.compact )
                //     this.$refs.barcode2.focus();
                // else
                //     this.$refs.barcode.focus();
            }, 100);
        },
    },
    components: { 
        CustomerInfo, 
        PackageViewer, 
        ForceDialog,
        // BarcodeField 
        },
    directives: { mask }
}
</script>

<style lang="scss" scoped>
.modified {
    border: 3px solid #FF5722;
}
</style>